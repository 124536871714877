<template>
  <div class="pageContol listWrap templateList">
    <div class="framePage">
      <div class="framePage-title">
        <span>
          <em>当前位置：</em>
          <a href="javascript:;">运营工具</a>
          <i>></i>
          <a href="javascript:;" class="cur-a">订单退款列表（京训钉）</a>
        </span>
      </div>
      <div class="framePage-body">
        <div class="operationControl flexdc" style="align-items: flex-start">
          <div class="searchbox" style="margin-bottom:15px">
            <div title="订单编号" class="searchboxItem ci-full">
              <span class="itemLabel">订单编号:</span>
              <el-input
                v-model="orderNo"
                clearable
                placeholder="请输入订单编号"
                size="small"
              ></el-input>
            </div>
            <div title="退款订单编号" class="searchboxItem ci-full">
              <span class="itemLabel" style="min-Width:7rem">退款订单编号:</span>
              <el-input
                v-model="refundNo"
                clearable
                placeholder="请输入退款订单编号"
                size="small"
              ></el-input>
            </div>
                <div title="创建日期" class="searchboxItem ci-full">
              <span class="itemLabel">创建日期:</span>
              <el-date-picker
                clearable
                size="small"
                v-model="orderCreateTime"
                type="daterange"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                format="yyyy-MM-dd"
                value-format="yyyy-MM-dd"
              ></el-date-picker>
            </div>
          </div>

          <div class="searchbox">
        
            <div title="退款日期" class="searchboxItem ci-full">
              <span class="itemLabel">退款日期:</span>
              <el-date-picker
                clearable
                size="small"
                v-model="refundCreateTime"
                type="daterange"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                format="yyyy-MM-dd"
                value-format="yyyy-MM-dd"
              ></el-date-picker>
            </div>
            
            <div class="df" style="padding-left:10px">
              <el-button type="primary" class="bgc-bv" round @click="getData()"
                >查询</el-button
              >
              <el-button type="primary" class="bgc-bv" round @click="exportJxd()"
                >导出</el-button
              >
              
            </div>
          </div>
        </div>
        <div class="framePage-scroll">
          <div class="ovy-a">
            <el-table
              ref="multipleTable"
              :data="tableData"
              :height="tableHeight"
              size="small"
              tooltip-effect="dark"
              style="width: 100%"
              :header-cell-style="tableHeader"
              stripe
            >
             <el-table-column
                label="序号"
                align="center"
                type="index"
                :index="indexMethod"
                fixed
              />
              <el-table-column
                label="退款编号"
                align="left"
                show-overflow-tooltip
                prop="refundNo"
                min-width="210px"
              />
              <el-table-column
                label="订单编号"
                align="left"
                show-overflow-tooltip
                prop="orderNo"
                min-width="210px"
              />
              <el-table-column
                label="订单总额（元）"
                align="left"
                show-overflow-tooltip
                prop="price"
                min-width="120px"
              />
              <el-table-column
                label="退款金额（元）"
                align="left"
                show-overflow-tooltip
                prop="refundPrice"
                min-width="120px"
              />
              <el-table-column
                label="创建时间"
                align="left"
                show-overflow-tooltip
                prop="orderCreateTime"
                min-width="150px"
              >
                <template slot-scope="scope">
                  {{ scope.row.orderCreateTime | moment }}
                </template>
              </el-table-column>
              <el-table-column
                label="退款时间"
                align="left"
                show-overflow-tooltip
                prop="createTime"
                min-width="150px"
              >
                <template slot-scope="scope">
                  {{ scope.row.createTime | moment }}
                </template>
              </el-table-column>
              <el-table-column label="操作" align="center" width="180px" fixed="right">
                <div slot-scope="scope">
                  <el-button
                    type="text"
                    size="mini"
                    style="padding:0 5px"
                    @click="
                      lookOrder(
                          scope.row,
                        scope.row.id,
                      )
                    "
                    >查看</el-button
                  >
                </div>
              </el-table-column>
              <Empty slot="empty" />
            </el-table>
          </div>
        </div>
        <PageNum
          :apiData="apiData"
          @sizeChange="sizeChange"
          @getData="getData"
        />
      </div>
    </div>
  </div>
</template>
<script>
import Empty from "@/components/Empty.vue";
import PageNum from "@/components/PageNum.vue";
import List from "@/mixins/List";
import moment from "moment";
import pdf from "pdfobject";
import { mapGetters } from "vuex";
import { resetKeepAlive } from "@/utils/common";
export default {
  name: "workOrder/JxdOrderList",
  components: {
    Empty,
    PageNum,
  },
  mixins: [List],
  data() {
    return {
      orderNo: "", //订单编号
      refundNo: "", //退款订单编号
      status: "",
      orderCreateTime: [], //创建日期
      refundCreateTime: [], //支付日期
      statusList: [
        { value: "0", label: "待付款" },
        { value: "1", label: "已付款" },
        { value: "2", label: "已关闭" },
        { value: "3", label: "已完成" },
        { value: "4", label: "已付款有退款" },
      ],
    };
  },
  watch: {},
  created() {},
  computed: {
    ...mapGetters({
      downloadItems: "getDownloadItems",
      userJson: "getUser",
    }),
  },
  mounted() {
    this.getTableHeight();
  },
  methods: {
    getareatree() {
      this.$post("/sys/area/tree").then((ret) => {
        let data = ret.data || [];
        data.map((el) => {
          if (el.children) {
            el.children.map((els) => {
              els.disabled = false;
              if (els.children) {
                els.children.map((els1) => {
                  els1.disabled = false;
                });
              }
            });
          }
        });
        this.areatreeList = data;
      });
    },
    getData(pageNum = 1) {
      const params = {
        pageNum: pageNum,
        pageSize: this.pageSize,
      };
      if (this.orderNo) {
        params.orderNo = this.orderNo;
      }
      if (this.refundNo) {
        params.refundNo = this.refundNo;
      }
      if (this.orderCreateTime) {
        params.orderCreateTimeStart = this.orderCreateTime[0];
        params.orderCreateTimeEnd = this.orderCreateTime[1];
      }
      if (this.refundCreateTime) {
        params.refundCreateTimeStart = this.refundCreateTime[0];
        params.refundCreateTimeEnd = this.refundCreateTime[1];
      }
      this.doFetch({
        url: "/biz/ding/refundOrder/pageList",
        params,
        pageNum,
      });
    },
    exportJxd() {
         const params = {
      };
     if (this.orderNo) {
        params.orderNo = this.orderNo;
      }
      if (this.refundNo) {
        params.refundNo = this.refundNo;
      }
      if (this.orderCreateTime) {
        params.orderCreateTimeStart = this.orderCreateTime[0];
        params.orderCreateTimeEnd = this.orderCreateTime[1];
      }
      if (this.refundCreateTime) {
        params.refundCreateTimeStart = this.refundCreateTime[0];
        params.refundCreateTimeEnd = this.refundCreateTime[1];
      }
      
        this.$post('/biz/ding/refundOrder/exportPageList',params).then(ret => {
          window.open(ret.message);
        })
    },
    lookOrder(row,id) {
        this.$router.push({
        path: "/web/workOrder/JxdRefundListDetail",
        query:{
            getClassInfo: JSON.stringify({
            row
          }),
            id
        }
      });
    },
    getTableHeight(opDom = true, page = true) {
      let tHeight =
        window.innerHeight - (3 + 1.35 + 3.25 + 0.675 + 3.25 + 1.35 ) * 16;
      if (opDom) {
        tHeight -= 40 + 0.675 * 16 + 1;
      }
      if (page) {
        tHeight -= 40;
      }
      this.tableHeight = tHeight;
      // // 40为thead高 | trHeight默认45为带按钮的tbody的tr高
      // this.pageSize = Math.ceil((tHeight -40) / trHeight);
    },
  },
  beforeRouteLeave: resetKeepAlive,
  watch: {
    // 监听缓存后调用接口
    $route: {
      handler: function(route) {
        if (route.query.refresh == true) {
          this.getData();
        }
        // this.activeName = route.params.refName;
      },
      deep: true,
    },
  },
};
</script>
<style lang="less" scope>
.upload-btns {
  height: 40px;
  .el-upload {
    height: 40px !important;
    border: none !important;
  }
}
.upload-btn {
  width: 5rem;
  height: 28px;
  margin-top: 15px;
  .el-upload {
    width: 5rem;
    height: 28px !important;
    border: none !important;
  }
}
.el-collapse-item__header {
  height: 21px;
  line-height: 21px;
  background: none;
  .el-collapse-item__content {
    margin-bottom: 10px !important;
  }
}
.el-collapse-item__wrap {
  background: none;
}
.remind {
  .el-form-item__content {
    line-height: 1.5rem;
    .colorkey {
      color: tomato;
    }
  }
}
.rejectBox {
  display: flex;
  align-items: center;
  span {
    padding-left: 0.25rem;
    font-size: 0.9rem;
  }
}
.el_CardRed {
  background-color: #faebeb;
  color: #f36464;
  border: 1px solid #faebeb;
}
.el_CardGreen {
  background-color: #eaf7f4;
  color: #5dd9b9;
  border: 1px solid #eaf7f4;
}
.el_CardBlue {
  background-color: #eaeffb;
  color: #598efe;
  border: 1px solid #eaeffb;
}
.bgc-redd:hover,
.bgc-redd:focus,
.bgc-redd:disabled,
.bgc-redd:disabled:hover {
  background-color: #e33a1d;
  border-color: #e33a1d;
  color: #fff;
}

.bgc-blued:hover,
.bgc-blued:focus,
.bgc-blued:disabled,
.bgc-blued:disabled:hover {
  background-color: #248bff;
  border-color: #248bff;
  color: #fff;
}
.bgc-green:hover,
.bgc-green:focus,
.bgc-green:disabled,
.bgc-green:disabled:hover {
  background-color: #08c6a2;
  border-color: #08c6a2;
  color: #fff;
}
</style>
<style lang="less">
.dialog-footers {
  .el-textarea__inner {
    resize: none;
    min-height: 100px !important;
  }
}
.sjd {
  width: 5.2rem;
  input {
    border: none;
    border-bottom: 1px solid #dcdfe6;
    border-radius: 0;
  }
}
</style>
